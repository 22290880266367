import React from "react";
import useForm from "react-hook-form";
import { Grid, makeStyles, Button, TextField } from "@material-ui/core";
import InputMask from "react-input-mask";
import ScrollTo from "scroll-to-element";
import Axios from "axios";

import Destaque from "./images/destaque.png";
import TextBackground from "./images/texture2.png";
import OracleFooterLogo from "./images/logo-footer.png";
import Effect1 from "./images/effect1.png";
import Effect2 from "./images/effect2.png";
import ButtonArrow from "./images/seta.png";
import FooterBackground from "./images/texture2.png";
import PageBackground from "./images/bg.png";
import OracleLogo from "./images/logo.png";

import PDF from "./documents/FY20157APT_EBOOK_APPS_PDF_Historia-da-Oracle-Cloud---Apl.pdf";

function App() {
  const styles = useStyles();
  const { register, errors, handleSubmit } = useForm();

  const scrollToForm = () => {
    ScrollTo("#form", {
      offset: 0,
      duration: 500
    });
  };

  const onSave = async data => {
    try {
      const offerId = "5269";
      const url =
        " https://rex0rqa386.execute-api.us-east-1.amazonaws.com/dev/email/send";

      const urlParams = new URLSearchParams(window.location.search);
      var aff_id = urlParams.get("aff_id");
      var transaction_id = urlParams.get("transaction_id");
      var utm_source = urlParams.get("utm_source");
      var aff_sub = urlParams.get("aff_sub");

      var cellphone = data.phone.replace(/\D/g, "");
      if (aff_id === null) {
        aff_id = "1184";
      }

      let params = {
        offerId,
        firstname: data.name,
        lastname: data.lastName,
        email: data.email,
        country: data.country,
        city: data.city,
        company: data.company,
        occupation: data.jobRole,
        aff_id,
        affiliate_id: aff_id,
        transaction_id,
        utm_source,
        product_choice: "immakers",
        product_choice2: "landingpage",
        product_choice3: `${cellphone}`,
        ext_affiliate_id: aff_id //esse vai assumiar o affiliate_id no hive
      };

      if (cellphone.length === 13) {
        params.cellphone = cellphone.substring(2);
      } else {
        params.phone = cellphone.substring(2);
      }

      const resp = await Axios.post(url, params);

      if (resp.data.message.status === 200) {
        alert("Obrigado pelo contato, clique em OK para abrir o E-book");
        window.open(PDF, "_blank");
      } else {
        alert(
          "Houve algum problema, por favor verifique seus dados e tente novamente"
        );
      }
    } catch (error) {
      alert("Não foi possível salvar seus dados.");
    }
  };

  return (
    <Grid container justify="center">
      <Grid className={styles.container} container justify="center">
        <Grid className={styles.titleArea} item xs={12}>
          <Grid
            container
            justify="center"
            className={styles.title + " default-pattern"}
          >
            <img src={OracleLogo} alt="Oracle ERP Cloud" className="logo" />
            <Grid className="text" item>
              <h1>Oracle ERP Cloud</h1>
              <p>
                Descubra como o sistema de gestão empresarial da Oracle{" "}
                <b>
                  unifica dados em tempo real para aprimorar a tomada de
                  decisões
                </b>
                .
              </p>
              <div style={{ textAlign: "center" }}>
                <Button className="download" onClick={scrollToForm}>
                  <span>Baixe o ebook</span>
                  <img src={ButtonArrow} alt="button arrow" />
                </Button>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          className={styles.descriptionArea + " default-pattern"}
          item
          style={{ width: 720 }}
        >
          <h3>
            <b>
              Conheça<br></br>os principais<br></br>benefícios
            </b>
            :
          </h3>
          <div className="line" />
          <ul className="items" style={{ paddingLeft: 22 }}>
            <li>
              Use o <b>ERP reconhecido como líder</b> do Quadrante Mágico do
              Gartner em suítes de gerenciamento financeiro em nuvem.
            </li>
            <li>
              Tenha <b>dados unificados</b> em tempo real.
            </li>
            <li>
              Adote o modelo SaaS e <b>reduza custos</b> com software e
              infraestrutura.
            </li>
            <li>
              Gere <b>insights estratégicos</b> com inteligência artificial.
            </li>
            <li>
              Conte com tecnologias emergentes para <b>otimizar processos</b>.
            </li>
            <li>
              <b>Elimine tarefas repetitivas</b> e libere sua equipe para
              inovar.
            </li>
            <li>
              Conte com as <b>tecnologias de segurança mais recentes</b>.
            </li>
          </ul>
        </Grid>
      </Grid>
      <Grid id="form" container>
        <form onSubmit={handleSubmit(onSave)}>
          <Grid container justify="center" className="default-pattern">
            <Grid
              item
              style={{ textAlign: "center", marginTop: 83, marginBottom: 50 }}
            >
              <h3 style={{ fontSize: 22 }}>
                Preencha os dados, baixe nosso ebook<br></br> e{" "}
                <b>confira casos de sucesso da Oracle</b>
              </h3>
            </Grid>
            <Grid item xs={12} />
            <Grid item className="input-space" xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                placeholder="Nome"
                name="name"
                inputRef={register({ required: true })}
                helperText={errors.name && "Campo obrigatório"}
              />
            </Grid>
            <Grid item xs={12} />
            <Grid item className="input-space" xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                placeholder="Sobrenome"
                name="lastName"
                inputRef={register({ required: true })}
                helperText={errors.lastName && "Campo obrigatório"}
              />
            </Grid>
            <Grid item xs={12} />
            <Grid item className="input-space" xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                placeholder="E-mail"
                name="email"
                inputRef={register({
                  required: true,
                  pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                })}
                helperText={errors.email && "Campo inválido"}
              />
            </Grid>
            <Grid item xs={12} />
            <Grid item className="input-space" xs={12}>
              <InputMask mask={"+99 99 [9]9999-9999"}>
                {inputPops => (
                  <TextField
                    variant="outlined"
                    fullWidth
                    placeholder="Telefone (+55 11 XXXXX-XXXX)"
                    name="phone"
                    inputRef={register({
                      required: true,
                      pattern: /^\+[0-9]{2}\s[0-9]{2}\s(?:\[\9\]|\[_\])(?:[0-9]{4}|[0-9]{5})-[-[0-9]{4}$/
                    })}
                    helperText={errors.phone && "Campo inválido"}
                  />
                )}
              </InputMask>
            </Grid>
            <Grid item xs={12} />
            <Grid item className="input-space" xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                placeholder="País"
                name="country"
                inputRef={register({ required: true })}
                helperText={errors.country && "Campo obrigatório"}
              />
            </Grid>
            <Grid item xs={12} />
            <Grid item className="input-space" xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                placeholder="Cidade"
                name="city"
                inputRef={register({ required: true })}
                helperText={errors.city && "Campo obrigatório"}
              />
            </Grid>
            <Grid item xs={12} />
            <Grid item className="input-space" xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                placeholder="Nome da empresa"
                name="company"
                inputRef={register({ required: true })}
                helperText={errors.company && "Campo obrigatório"}
              />
            </Grid>
            <Grid item xs={12} />
            <Grid item className="input-space" xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                placeholder="Cargo"
                name="jobRole"
                inputRef={register({ required: true })}
                helperText={errors.jobRole && "Campo obrigatório"}
              />
            </Grid>
            <Grid item xs={12} />
            <Grid item style={{ textAlign: "right", marginTop: 8, width: 516 }}>
              <Button className={styles.formDownload} type="submit">
                <span>Baixe o ebook</span>
                <img
                  src={ButtonArrow}
                  alt="Arrow button"
                  style={{ marginLeft: 16 }}
                />
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
      <Grid className={styles.commentsContainer} container justify="center">
        <img src={Effect1} alt="Oracle" className="effect1" />
        <img src={Effect2} alt="Oracle" className="effect2" />
        <Grid container className="default-pattern" style={{ width: 720 }}>
          <Grid item xs={12} style={{ marginBottom: 60 }}>
            <p className="comment">
              "A solução da Oracle nos ajudou a ser mais produtivos. Também
              temos informações em tempo real, que tomadores de decisão, como
              nosso Conselho Administrativo, agradecem muitíssimo."
            </p>
            <p className="person">
              <b>Selene Arregoitia</b>
              <br></br>
              Gerente de processos de transformação, rede de restaurantes Las
              Alitas.
            </p>
          </Grid>
          <Grid item xs={12}>
            <p className="comment">
              "A gente está transformando nossa solução ERP em uma solução na
              nuvem, porque acreditamos que, realmente, esssa é a tendência de
              mercado e é o que vai fazer nossa operação ser mais fluida, mais
              produtiva."
            </p>
            <p className="person">
              <b>Tatiane Moraes</b>
              <br></br>
              Head Backoffice, brMalls.
            </p>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        className={styles.footer + " default-pattern"}
        container
        justify="center"
      >
        <Grid container className="content">
          <Grid item xs={12}>
            <span>wwww.oracle.com.br</span>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles({
  container: {
    backgroundImage: `url(${PageBackground})`
  },
  titleArea: {
    position: "relative",
    backgroundImage: `url(${Destaque}), url(${TextBackground})`,
    backgroundPosition: "top center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "auto",
    justifyItems: "center"
  },
  title: {
    display: "flex",
    position: "relative",
    alignItems: "flex-start",
    height: "100%",
    width: 720,
    paddingTop: 494,
    margin: "auto",
    "@media screen and (max-width: 600px)": {
      paddingTop: 0
    },
    "& .logo": {
      position: "absolute",
      top: 82,
      left: 50,
      "@media screen and (max-width: 600px)": {
        left: 20,
        top: 52
      }
    },
    "& h1": {
      fontFamily: "Georgia",
      fontSize: 45,
      fontWeight: 400,
      color: "white",
      marginBottom: 10,
      "@media screen and (max-width: 600px)": {
        marginTop: 490
      }
    },
    "& p": {
      fontSize: "25px",
      color: "white"
    },
    "& .download": {
      width: "403px",
      maxWidth: "100vw",
      "@media screen and (max-width: 600px)": {
        width: 300
      },
      "@media screen and (max-width: 400px)": {
        width: "100%",
        margin: "67px 0"
      },
      backgroundColor: "var(--yellow)",
      padding: "24px 0",
      color: "white",
      fontSize: 18,
      borderRadius: "100px",
      textTransform: "none",
      margin: "67px 30px",
      "& .MuiButton-label span": {
        fontFamily: "OracleSansBold",
        fontWeight: "700",
        fontSize: "20px",
        "@media screen and (max-width: 400px)": {
          fontSize: 18
        }
      },
      "& img": {
        marginLeft: 16
      }
    }
  },
  descriptionArea: {
    backgroundImage: `url(${TextBackground})`,
    backgroundPosition: "top center",
    color: "white",
    "& h3": {
      fontSize: 45,
      fontWeight: "700",
      lineHeight: 1,
      marginTop: 8
    },
    "& .line": {
      marginTop: 45,
      marginBottom: 42,
      width: 48,
      height: 6,
      backgroundColor: "var(--green)"
    },
    "& li": {
      fontSize: 25
    },
    "& li:not(:last-child)": {
      marginBottom: 36
    },
    "& li:last-child": {
      marginBottom: 76
    }
  },
  formDownload: {
    backgroundColor: "var(--green)",
    width: "290px",
    height: "57px",
    marginBottom: 72,
    color: "white",
    fontSize: 18,
    borderRadius: "100px",
    "&:hover": {
      backgroundColor: "var(--green)"
    },
    "& .MuiButton-label span": {
      fontFamily: "OracleSansBold",
      fontWeight: "700",
      textTransform: "none",
      fontSize: "19.8px"
    }
  },
  commentsContainer: {
    backgroundColor: "#f2eeeb",
    position: "relative",
    paddingTop: 81,
    paddingBottom: 138,
    "& .effect1": {
      position: "absolute",
      top: "-52px",
      left: 0
    },
    "& .effect2": {
      position: "absolute",
      bottom: 0,
      right: 0
    },
    "& .comment": {
      fontSize: 22,
      marginBottom: 31,
      color: "#25211e"
    },
    "& .person": {
      fontSize: 22,
      color: "#25211e"
    }
  },
  footer: {
    backgroundImage: `url(${FooterBackground})`,
    color: "white",
    "& .content": {
      width: 720,
      paddingLeft: 100,
      minHeight: 125,
      display: "flex",
      alignItems: "center",
      position: "relative",
      backgroundImage: `url(${OracleFooterLogo})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "bottom right 100px",
      "@media screen and (max-width: 1280px)": {
        backgroundPosition: "bottom right 0",
        paddingLeft: 0
      },
      "& span": {
        fontSize: 20,
        color: "white"
      }
    }
  }
});

export default App;
